/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/poppins-latin-ext-400.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/poppins-latin-400.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/poppins-latin-ext-600.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/poppins-latin-600.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


html {
  height: 100% !important;
}

html,
body {
  background: #F0F2F5 !important;
  font-family: 'Poppins', sans-serif !important;
  /* font-family:Arial, Helvetica, sans-serif; */
  font-size: 14px;
  line-height: 1.6em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-weight: 500 !important;
}

#root {
  height: 100% !important;
}

.app {
  height: 100% !important;
}

a {
  text-decoration: none;
}

.frlight {
  font-family: "robotolight";
}

.frreg {
  font-family: "robotoregular";
}

.frbold {
  font-family: "robotobold";
}

.frmed {
  font-family: "robotomedium";
}

.web-text {
  color: #0a5c96 !important;
}

.web-text1 {
  color: #171717;
}

.web-text2 {
  color: #636363;
}

.web-text3 {
  color: #565656;
}

.web-text4 {
  color: #005994;
}

.web-text5 {
  color: #303030;
}

.web-text6 {
  color: #575757;
}

.web-text7 {
  color: #969696;
}

.web-text10 {
  color: #2b4378;
}

.web-text11 {
  color: #DC1C1C;
}

.clr-green {
  color: #025e2a;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-28 {
  font-size: 28px;
}

.f-32 {
  font-size: 32px;
}

.mt-32 {
  margin-top: 34px;
}

.bg-blue {
  background: #005994 !important;
}

.section-bgwhite1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 35px 80px;
}

.tabcontent-align .tab-content {
  padding: 25px 30px;
}



/* login code  */

.bg {
  background: url(../public/images/bg.svg) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: #0068ae;
  font-size: 40px;
  margin: 0 0 30px;
}

.login .form-control,
.login .form-select {
  background: #f1f7fc;
  border-radius: 12px;
  height: 60px;
  font-size: 16px;
  padding: 20px;
  border: 0px;
  text-align: left;
}

.login .form-select {
  background: #f1f7fc;
  border-radius: 12px;
  height: 60px;
  font-size: 16px;
  padding: 10px 20px;
  border: 0px;
  text-align: left;
}

.login {
  padding: 30px;
}

.loginbtn {
  background: #0068ae;
  color: #fff;
  font-size: 20px;
  border-radius: 12px;
  width: 260px;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.oktag {
  color: #0068ae;
  font-size: 20px;
  position: relative;
  border-bottom: 1px solid #0068ae;
}

.oktag span {
  position: absolute;
  top: -17px;
  background: #fff;
  padding: 0 15px;
}


/* input[type="password"] {
  background: #F1F7FC url(../public/images/eye.png) no-repeat 95% 60%;
} */

p.c-tag {
  position: fixed;
  top: 30px;
  left: 30px;
  font-size: 24px;
  color: #1e1e1e;
}

p.s-tag {
  position: fixed;
  top: 30px;
  right: 30px;
  font-size: 20px;
  color: #1e1e1e;
}


/* login code end */


/* header*/

span.navbar-brand {
  background: #fff;
  padding: 0px;
  border-radius: 15px;
  font-size: var(--theme-context-fs) !important;
  /* text-align: left; */
  font-weight: 600;
}

span.navbar-brand h6 {
  font-size: var(--theme-header-fs) !important;
}

span.navbar-brand p {
  font-size: var(--theme-small-fs);
  color: var(--theme-header-secondary-color) !important;
}

.cnavbar {
  background: #ffffff;
  border-radius: 0;
  border-bottom: 1px solid #DDDDDD;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  padding-block: 6px;
}


/* 4/15-22*/

.navbar #button-addon2 {
  background: #fff;
  border: 0;
  height: 40px;
  padding: 5px 15px 5px 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}


/* .navbar .h-dwn .form-select{
  text-align: center;
} */


/* 4/15-22*/


/* 4/15-22*/

.navbar-brand img {
  width: 40px;
  height: 40px;
}

.navbar-brand small {
  font-weight: 400;
}


/* header code end */

.customcard {
  word-wrap: break-word;
  background: #fff !important;
  background-clip: border-box;
  border: 1px solid transparent !important;
  border-radius: 20px !important;
  /* -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%); */
  /* box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%); */
}

.container-tabs .tab-content {
  padding: 0;
  border-top: 0.5px solid #000000;
}

.container-tabs .custom-dlex-tabs {
  border-bottom: none !important;
}

.container-tabs .search-control {
  border-radius: 20px;
}

.container-tabs .form-control {
  border: 1px solid #000000;
}

.container-tabs {
  position: relative;
}


/* header tabs code */

.nav-tabs .nav-link.active {
  border-bottom: 5px solid #2b4378;
  border-left: 0;
  border-right: 0;
  color: #000;
}

.nav-tabs .nav-link {
  color: #565656;
  border-radius: 0;
  border: 0;
}


/* header tabs code end */


/* header custom  dropdown */

ul.dwnnavbar {
  flex-direction: row;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
}

ul.dwnnavbar li:first-child,
ul.dwnnavbar li:first-child a {
  border-top-left-radius: 20px;
}

ul.dwnnavbar li:last-child,
ul.dwnnavbar li:last-child a {
  border-top-right-radius: 20px;
}

ul.dwnnavbar li {
  padding: 0px 5px;
  margin-right: 40px;
}

ul.dwnnavbar li.nav-item a.nav-link,
ul.dwnnavbar li.nav-item a.nav-link.active {
  color: #092c44;
}

ul.dwnnavbar li:hover {
  color: #092c44;
  /* background: #F3FAFF 0% 0% no-repeat padding-box; */
  border-bottom: 3px solid #092c44;
}

ul.dwnnavbar li {
  /* background: #F3FAFF 0% 0% no-repeat padding-box; */
  border-bottom: 3px solid #fff;
}

hr.hrline {
  color: #f3faff;
  opacity: 1;
  width: 97%;
  margin: 10px auto;
  height: 2px;
}

ul.list-dropdown-custom li:first-child:hover {
  background: #efedff;
}


/* ul.list-dropdown-custom li:first-child{
  background:  url(../public/images/org-dd.svg) no-repeat 5px 12px;
}

ul.list-dropdown-custom li:nth-child(2){
  background:  url(../public/images/adm-dd.svg) no-repeat 5px 17px;
}

ul.list-dropdown-custom li:nth-child(3){
  background:  url(../public/images/comc-dd.svg) no-repeat 5px 12px;
}
ul.list-dropdown-custom li:nth-child(4){
  background:  url(../public/images/fin-dd.svg) no-repeat 5px 15px;
}
ul.list-dropdown-custom li:nth-child(5){
  background:  url(../public/images/hr-dd.svg) no-repeat 5px 12px;
} */


/* ul.list-dropdown-custom li:first-child:hover{
  background: #EFEDFF url(../public/images/org-dd.svg) no-repeat 5px 12px !important
}
ul.list-dropdown-custom li:nth-child(2):hover{
  background: #EFEDFF url(../public/images/adm-dd.svg) no-repeat 5px 17px !important
}
ul.list-dropdown-custom li:nth-child(3):hover{
  background: #EFEDFF url(../public/images/comc-dd.svg) no-repeat 5px 12px !important
}
ul.list-dropdown-custom li:nth-child(4):hover{
  background: #EFEDFF url(../public/images/fin-dd.svg) no-repeat 5px 15px !important
}
ul.list-dropdown-custom li:nth-child(5):hover{
  background: #EFEDFF url(../public/images/hr-dd.svg) no-repeat 5px 12px !important
} */


/* header custom  dropdown end*/


/* side bar nav*/

.offcanvas-header {
  justify-content: flex-end;
}

.offcanvas-body .form-select {
  width: 135px;
  height: 38px;
  border: 1px solid #005994;
  box-shadow: 0px 0px 6px #0000000f;
  color: #005994;
  border-radius: 9px;
  margin: auto;
}

.offcanvas-body .btn {
  height: 46px;
  background: #ffffff;
  border: 1px solid 005994;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #005994;
}

.offcanvas-body .btn:hover {
  color: #005994;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
}

.settings .card {
  background: #f3faff;
}

.settings .card p {
  color: #0a5c96;
}

figcaption.frmed.figure-caption {
  color: #171717;
  font-size: 20px;
}


/* side bar nav*/


/* content tabs*/

.content-tabs {
  border-radius: 20px;
}

.content-tabs ul.nav.nav-tabs {
  border-radius: 0;
  padding: 0;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #555555;
}

.content-tabs .nav-tabs .nav-link.active {
  font-size: 1rem;
  font-family: "robotobold";
  padding: 0.229rem 1rem;
}

.content-tabs ul.nav.nav-tabs li.nav-item,
.content-tabs ul.nav.nav-tabs li.nav-item .nav-link {
  border-top-left-radius: 20px;
}

.content-tabs .tab-content {
  width: 100% !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.content-tabs .tab-content.c-radius {
  border-radius: 18px !important;
}

.custom-control,
.custom-control.form-select {
  border: 0.5px solid #000000;
  border-radius: 10px;
  color: #000;
  height: 40px;
  font-size: 20px;
}

.custom-control:focus,
.custom-control:hover,
.custom-control.form-select:focus,
.custom-control.form-select:hover {
  background: #ebebeb;
  border-radius: 10px;
  box-shadow: none;
  border: 0px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.btn-reset {
  min-width: 223px;
  border: 1px solid #005994;
  font-size: 24px;
  background: transparent;
  border-radius: 8px;
  color: #005994;
  height: 52px;
  margin-top: 94px;
}

.btn-reset:hover {
  background: #005994;
  color: #fff;
}

.btn-bg-secondary {
  background: #f3faff;
  border-radius: 8px !important;
  margin-left: 13px !important;
}

.content-tabs label.form-label {
  color: #666666;
  font-size: 14px;
  font-family: "robotomedium";
}

.content-tabs ul.custom-dlex-tabs.nav.nav-tabs {
  display: flex;
  justify-content: initial;
}

.secondary-label {
  --bs-text-opacity: 1;
  border: 2px solid transparent;
  padding: 1px 2px;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.secondary-label:hover {
  border: 2px solid #0a58ca;
  border-radius: 5px;
  color: #0a58ca;
}


/* content tabs*/


/* custom buttons */

.bdHMCv {
  max-height: 40px;
  min-height: 40px;
  border: 0.5px solid #000000;
  border-radius: 20px;
  box-shadow: none;
  height: 40px !important;
}

.bdHMCv>.wrapper {
  border: 0 !important;
  border-radius: 20px;
  box-shadow: none;
  height: 37px !important;
}

.bdHMCv>.wrapper:focus,
.bdHMCv:focus {
  background: #ebebeb;
}


/* toogle buttons*/

.checkbox {
  width: 50px;
  padding: 3px 0;
}

.checkbox input[type="checkbox"] {
  margin: 0;
  visibility: hidden;
  left: 1px;
  top: 1px;
  width: 0;
}

.checkbox label {
  background: #d50a1f;
  width: 50px;
  height: 15px;
  border-radius: 40px;
  box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  transition: all 0.25s;
}

.checkbox label:before {
  content: "\f00d";
  color: #fff;
  background: #d50a1f;
  font-family: "Font Awesome 5 Free";
  font-size: 11px;
  font-weight: 900;
  text-align: center;
  line-height: 22px;
  width: 22px;
  height: 22px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  transition: all 0.25s;
}

.checkbox input[type="checkbox"]:checked+label {
  background: #00d12a;
}

.checkbox input[type="checkbox"]:checked+label:before {
  content: "\f00c";
  color: #fff;
  background: #00d12a;
  left: 29px;
}


/* card settings */

.cardgroup-custom {
  flex-flow: row;
}

.card-group {
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
}

.card-group .carddiv {
  height: auto;
}

.cardbg {
  background: #f3faff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 7px 0 4px -2px #0000001a;
  z-index: 1;
  height: 100%;
  width: 150px;
}

.cardbg .card-title {
  font-size: 18px;
  color: #005994;
  margin-top: 10px;
}

.cardgroup-custom .card-text {
  font-size: 16px;
  cursor: pointer;
}

.cardgroup-custom .card-body {
  padding: 7px 25px;
  margin: 12px 0;
}

.border-left {
  border-left: 1px solid #0059941f;
}


/*Assign Sections */

.bg-section {
  background: #f2f7fa;
  border-radius: 18px;
  padding: 35px 40px;
}

.inner-section-small {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 1px solid #606060;
  border-radius: 16px;
  padding: 10px 0;
}


/* 17-04-22 */

.inner-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #00599499;
  border-radius: 16px;
  padding: 20px 35px;
}

.btn-save {
  background: #005994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
  font-family: "robotobold";
  font-size: 16px;
  height: 31px;
  line-height: 0;
  min-width: 75px;
}

.c-table thead th {
  text-align: center;
  border-bottom: 2px solid #00599499;
  color: #8f8f8f;
  font-size: 22px;
  padding: 10px;
  font-family: "robotomedium";
  font-weight: normal;
}

.c-table tbody td {
  text-align: center;
  border-bottom: 2px solid #70707014;
  color: #555555;
  font-size: 1rem;
}

.outline-assign {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #005994;
  border-radius: 6px;
  color: #005994;
  font-size: 16px;
  font-family: "robotobold";
  min-width: 86px;
  height: 31px;
  line-height: 0;
}

.check-label .form-check label {
  color: #252525;
  font-size: 24px;
  font-family: "robotoregular";
}

td.bg-td {
  background: #f2f6fa;
}

.modal-body .form-control.is-invalid,
.modal-body .form-select.is-invalid {
  border-color: #dc3545;
}

.list-group li {
  border: 0px;
  cursor: pointer;
  padding: 0;
}

.list-group li a:hover,
.list-group li a:active {
  background: #0059941a;
}

.list-group li a {
  text-decoration: none;
  padding: 5px 10px;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}

.list-group li a .fw-title {
  color: #303030;
  font-size: 20px;
  font-family: "robotomedium";
}

.list-group li a {
  color: #6e6e6e;
  font-size: 16px;
}

.list-group li a:hover .fw-title,
.list-group li a:active .fw-title {
  color: #005994;
}

.td-ip {
  width: 160px;
  height: 42px;
  background: #f2f6fa 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000029;
  border: 2px solid #00599480;
  border-radius: 10px;
  margin: auto;
}

/* Modal popup css*/

.modal-table .form-control,
.modal-table .form-select {
  font-size: 12px;
  box-shadow: none;
  border: 1px solid #00599499;
}

.modal-table th {
  font-size: 13px;
}

div#cmodal {
  max-width: 90%;
}

.modal-content {
  /* box-shadow: 0px 20px 40px #00000029; */
  /* border: 2px solid #00599466; */
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none !important;

}


/*Note */

.note h5 {
  color: #eb2a2a;
  font-size: 20px;
}

.note p {
  color: #eb2a2a;
  font-size: 18px;
}

.maclabel {
  display: flex;
  align-items: center;
}

.maclabel.form-check label {
  font-size: 28px;
  font-family: "robotomedium";
  padding-left: 10px;
}

.maclabel .form-check-input {
  width: 25px;
  height: 25px;
}

.border-b {
  border-bottom: 2px solid #00599499;
}

.ullist {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: wrap;
}

.ullist li {
  width: 20%;
}

.ullist li label {
  color: #252525;
  font-size: 16px;
}

.section-bgwhite {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #77777780;
  border-radius: 16px;
  margin-top: 30px;
  padding: 30px 20%;
}


/* Admissions*/

button.accordion-button.collapsed {
  padding: 15px 0;
  font-size: 14px;
  font-family: "robotolight";
}

.counter {
  color: #fff;
  background: #fff;
  text-align: center;
  padding: 20px 25px 10px;
  border-radius: 30px 0;
  box-shadow: 7px 7px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  width: 17%;
}

.counter:before {
  content: "";
  background: #51d658;
  border-radius: 15px 0 30px 0;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 12px;
  left: 8px;
  right: 8px;
  bottom: 10px;
  z-index: -1;
}

.counter h3 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px;
  font-family: "robotomedium";
  min-height: 35px;
}

.counter span {
  font-family: "robotolight";
  font-size: 14px;
}

.counter .counter-value {
  color: #666;
  background: #fff;
  font-size: 24px;
  font-weight: 600;
  text-align: right;
  line-height: 33px;
  width: 90%;
  height: 25px;
  padding: 0 15px;
  margin: 0 0 0 -20px;
  display: block;
}

.counter.pink:before {
  background-color: #ff5d94;
}

.counter.orange:before {
  background-color: #fc8720;
}

.counter.purple:before {
  background-color: #bf5dfe;
}

.counter.webcolor:before {
  background-color: #0068ae;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}


/*4-05-22 */

a.navbar-brand {
  background: #fff;
  padding: 5px 10px;
  border-radius: 20px;
}

a.navbar-brand p {
  font-size: 14px;
  line-height: 1.4;
  color: #092c44;
  font-weight: 600;
}

button#dropdown-basic {
  padding: 0 !important;
}

.sc-gsnTZi {
  z-index: 9999999999 !important;
  background: #fff !important;
  overflow: visible !important;
}

.bdHMCv>.wrapper {
  position: static !important;
}


/* 07-04-22 */

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  /* width: 300px !important; */
}

.react-multi-carousel-list {
  justify-content: center;
}

.react-multi-carousel-track .card {
  background: #2b4378;
  border-radius: 20px;
  color: #fff;
  height: 193px;
  margin-right: 20px;
  border: 0;
}

.react-multi-carousel-track .card .card-body {
  padding: 1.5rem;
}

.icon-div {
  background: #8d9ddb;
  width: 63px;
  height: 63px;
  border-radius: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-multi-carousel-track .card .card-body h4 {
  font-family: "robotomedium";
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 15px;
}

.react-multi-carousel-track .card .card-body h5 {
  font-size: 20px;
  font-weight: normal;
}

.react-multiple-carousel__arrow--right {
  right: 23px !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.div-min-wdth {
  min-width: 120px;
}

.btn-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 89, 148, 0.2);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 0 12px 15px 0;
}


/* 20-04-22 */

.cnavbar .dropdown-menu[data-bs-popper].options-menu {
  width: 172px;
}


/* .cnavbar .dropdown-menu[data-bs-popper] {
  right: -2px !important;
  left: inherit !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 0 !important;
  top: 46px;
} */

.cnavbar .dropdown-menu[data-bs-popper] .dropdown-item {
  padding: 10px !important;
  border-radius: 10px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  /* border: 1px solid var(--theme-secondary-color); */
  border-radius: 4px;
  padding-block: 6px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border: none;
  padding-bottom: 0px !important;
}


.navbar-expand-lg .navbar-nav .dropdown-menu:after {
  left: 33px;
  top: -7px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu:before {
  left: 33px;
  top: -8px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
}

.innertab-sec.paymenttab ul {
  border: 0px !important;
}

.innertab-sec.paymenttab ul li.nav-item .nav-link.active {
  background: #2b4378;
  border-radius: 30px !important;
  height: auto;
  color: #fff;
  border: 0px;
  font-size: 12px;
  font-family: "robotoregular";
}

.innertab-sec.paymenttab ul li.nav-item {
  width: 22%;
  position: relative;
  border-left: 0;
}

.innertab-sec.paymenttab ul li.nav-item::after {
  content: "";
  background: url(../public/images/fee-arrow.svg) no-repeat;
  width: 105px;
  height: 20px;
  position: absolute;
  top: 13px;
  right: -10px;
}

.innertab-sec.paymenttab ul li.nav-item:last-child:after {
  background: none;
}

.innertab-sec.paymenttab ul li.nav-item .nav-link {
  background: #f3f6ff;
  border-radius: 30px;
  color: #000;
  border: 0px;
  font-size: 12px;
  font-family: "robotoregular";
  min-width: 143px;
  padding: 8px 20px;
}

.btn-radius-0 {
  border-radius: 3px !important;
  min-width: auto !important;
  font-size: 13px;
}

.form-check-label.p-label {
  color: #757a7b !important;
  font-size: 14px !important;
}

.btn-save.btn-payment {
  color: #fff !important;
  font-family: "robotoregular";
  background: #2b4378;
  border-radius: 30px;
  border: 0px !important;
  min-width: 150px;
  padding: 8px 15px !important;
}

.btn-previous {
  font-family: "robotoregular";
  min-width: 110px;
  border-radius: 30px;
  background: #f3f6ff;
  color: #000;
  padding: 5px 15px !important;
}


/* was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e), url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e) no-repeat;
 
} */


/* .navbar-collapse.collapse{
  margin-bottom: 25px;
} */


/* timeline sce */

.timeline-title {
  font-weight: 700;
  font-size: 34px;
}

.updated-title {
  color: #555555;
  font-size: 16px;
  margin-bottom: 0;
}

.align-arwimg {
  left: -12%;
}

.timeline-section {
  margin: 30px 0 100px;
}

/* #cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
  border-top: 0.5px solid #555555;
} */

/* #cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  width: 1px;
  background: #555555;
} */

@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 10px;
    margin-bottom: 3em;
  }

  #cd-timeline::before {
    left: 20%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}

.cd-timeline-block::after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 1.5em 0;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #555555;
  background: #fff;
}

.cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

.cd-timeline-img.cd-picture {
  background: #75ce66;
}

.cd-timeline-img.cd-movie {
  background: #c03b44;
}

.cd-timeline-img.cd-location {
  background: #f0ca45;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #ffffff;
  /* padding: 1em; */
}

.cd-timeline-content::after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-content h2 {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

/* .cd-timeline-block:nth-child(odd) .cd-timeline-content {
  border-left: 4px solid #FFF172;
}

.cd-timeline-block:nth-child(even) .cd-timeline-content {
  border-left: 4px solid #FF7A72;
} */

.cd-timeline-content h2 a {
  text-decoration: none;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  font-size: 13px;
  font-size: 0.8125rem;
}

.cd-date::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: red;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  display: inline-block;
}

.cd-timeline-content p {
  margin: 0 0 7px;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  font-weight: 500;
}

.cd-timeline-content p a {
  text-decoration: none;
}

.cd-timeline-content .cd-read-more {
  float: right;
  padding: 0.8em 1em;
  background: #acb7c0;
  color: #ffffff;
  border-radius: 0.25em;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
  float: left;
  padding: 0.8em 0;
  opacity: 1;
  color: #555555;
  font-size: 20px;
}


/* .cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #ffffff;
} */

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}


/* Time line */

.accordion.accordion-flush {
  border-radius: 20px;
  min-height: 46px !important;
}

.accordion-header {
  height: 46px !important;
}

.accordion.accordion-flush .accordion-button {
  color: #555555;
  border-top: 1px solid #DDD !important;
  border-bottom: none !important;
  height: 46px !important;
  padding: 0 0 0 20px;
  font-family: "Lato", "Roboto", "serif" !important;
}

.accordion.accordion-flush .accordion-button.collapsed:last-child {
  border-bottom: 1px solid #DDD !important;
}

.accordion-body {
  padding: 0;
}

.accordion-button::after {
  background-image: url('/public/images/down-arrow.svg');
  background-size: 22px 22px;
  background-color: #F3F3F3;
  padding: 15px;
  padding-block: 24px;
  background-position-x: 5px;
  background-position-y: center;
}

.inner-acrd .accordion-button::after {
  border-top-right-radius: 0px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
  background-image: url('/public/images/down-arrow.svg');
  background-size: 22px 22px;
  padding: 15px;
  padding-block: 23px;
  background-position-x: 4px;
  background-position-y: center;
  border-top-right-radius: 0;
}

.accordion.accordion-flush .inner-acrd1 .accordion-button {
  border-radius: 0;
  color: #000;
  padding-left: 4%;
}

.accordion.accordion-flush .inner-acrd2 .accordion-button {
  background: #f3f6ff;
  border-radius: 0;
  color: #000;
  padding-left: 9% !important;
}

.accordion.accordion-flush .inner-acrd3 .accordion-button {
  padding-left: 6%;
}

.accordion.accordion-flush .accordion-collapse input[type="checkbox"] {
  margin-inline: 20px;
  margin-inline-end: 14px;
  width: 15px;
  height: 15px;
}

.accordion.accordion-flush .accordion-header input[type="checkbox"] {
  margin-inline-end: 16px;
  width: 15px;
  height: 15px;
}

.accordion-button:not(.collapsed) {
  color: #555555;
  background-color: #FFFFFF;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border-bottom-left-radius: 0 !important;
}

.management-section {
  margin-bottom: 40px;
}

.assignfee-accordion-header {
  margin-bottom: 0px;
  padding-bottom: 10px;
}


/* .management-section .react-multi-carousel-track .card1.card {
  border-radius: 20px;
  margin-right: 20px;
  border: 0;
  background: url(../public/images/slider-bg.png) no-repeat 0 0;
  background-size: cover;
  height: auto;

}

.management-section .react-multi-carousel-track .card1 .card-body {
  padding: 15px 20px;
}

.management-section .react-multi-carousel-track .card1 .card-body h4 {
  font-family: 'robotomedium';
  font-size: 30px;
  color: #000000;
  font-weight: normal;
  margin-bottom: 40px;
}

.management-section .react-multi-carousel-track .card1 .card-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3F3F3F;
  margin-bottom: 2px;
} */

.management-section {
  margin-bottom: 40px;
}

.ulcards {
  display: flex;
  list-style: none;
  padding: 0;
  flex-flow: wrap;
}

.ulcards li {
  width: 25%;
}

.ulcards li .card1.card {
  border-radius: 20px;
  margin-right: 20px;
  border: 0;
  background: linear-gradient(107.98deg, #dce2fa 2.28%, rgba(87, 108, 189, 0.81) 100%);
  /* background: url(../public/images/slider-bg.png) no-repeat 0 0; */
  background-size: cover;
  height: auto;
}

.ulcards li .card1 .card-body {
  padding: 15px 20px;
}

.ulcards li .card1 .card-body h4 {
  font-family: "robotomedium";
  font-size: 16px !important;
  color: #000000;
  font-weight: normal;
  margin-bottom: 40px;
}

.ulcards li .card1 .card-body p {
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  color: #3f3f3f;
  margin-bottom: 2px;
}

.drk-btn {
  background: #000000;
  border-radius: 30px 10px 10px 10px;
  min-width: 130px;
  font-size: 20px;
  color: #fff;
  height: 40px;
  line-height: 27px;
}


/* Upload Logos */

.custom-desc {
  width: 100%;
  font-size: 12px;
}

.tab-headers {
  font-size: 12px;
  margin: 0;
  padding-block-start: 1px;
}

.custom-btn {
  border-radius: 15px;
  padding: 8.2px 14.2px;
  font-size: 12px;
}

.custom-btn:focus {
  background: #ecf0ff;
  color: #2b4378;
}

.custom-btn:hover {
  background: #b8c5f4;
  color: #2b4378;
  border: 1px solid blue;
}

.selected-logo {
  background: #ecf0ff;
  color: #2b4378;
  border: 1px solid #ecf0ff;
}

.submit-upload {
  color: white;
  background-color: #2b4378;
}

.file-select-btn {
  border-radius: 4px;
  font-size: 12px;
  color: white;
  background-color: #2b4378;
  display: flex;
}


/* .file-select-btn:focus,
.file-select-btn:focus>.file-up-logo {
  background: #ecf0ff;
  color: #2b4378;
  border: 0;
} */


/* .file-select-btn:hover,
.file-select-btn:hover>.file-up-logo {
  background: #b8c5f4;
  color: #2b4378;
} */

.file-select-btn p {
  margin: 0;
  padding: 8px 15px 9px 8px;
  padding-inline-start: 10px;
}

.file-up-logo {
  width: 30px;
  height: 34px;
  /* background-color: #3f5c9c; */
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 0 0 15px;
}

.file-up-logo img {
  padding-inline-start: 4px;
}

.scale-inp,
.rotate-inp {
  /* width: 18.5%; */
  width: 36%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #555555;
}

.scale-inp input,
.rotate-inp input {
  padding: 0.6em 0;
  padding-inline-start: 0.9em;
  outline: none;
  border: 1.33px solid #555555;
  border-radius: 15px;
}

cropscale-img {
  width: min(100%, 246px);
  height: 246px;
}

.header-preview-row {
  padding: 0;
  width: 67%;
}

.header-preview-main {
  width: min(100%, 512px);
  padding: 0;
}

.header-preview-main>p {
  margin-block-end: 10px;
}

.header-preview {
  border: 2px dashed #8A9DE7;
  border-radius: 4px;
  padding: 14px 90px;
  background: #F5F5F8;
}

.header-preview-content {
  border-radius: 4px;
  display: flex;
  background: white !important;
}

.header-preview-content p {
  margin: 0;
  color: #000000;
  font-size: 10px;
  padding-inline-start: 13px;
  padding-inline-end: 51px;
  padding-block: 9px 9.73px;
}

.header-preview-content :is(canvas,
  img) {
  margin-block: 5px;
  margin-inline-start: 19px;
  align-self: center;
}

.upload-logo-start {
  color: #000000;
  margin-block-end: 0.6rem;
}

.upload-logo-text {
  margin-block-end: 0.7rem;
  color: #555555;
  letter-spacing: 0.2px;
}

.upload-logo-type {
  border-right: 1px solid #DDD;
}

.upload-bg-img-container {
  padding: 38px 32px !important;
  border: 2px dashed #E6E6E6 !important;
  border-radius: 6px;
  background: #F6FBFE;
}

.upload-bg-preview-container {
  padding: 38px 95px !important;
  border: 2px dashed #8A9DE7 !important;
  border-radius: 6px;
  position: relative;
}

.upload-bg-image {
  position: absolute;
  width: 243px !important;
  height: 247px !important;
  left: 100px;
  top: 11.8%;
}

.theme-tabs.free {
  width: fit-content !important;
  border-block-end: none !important;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 150px 300px;
  grid-auto-flow: dense;
  gap: 10px 13px;
}

.gallery-container .gallery-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-container .gallery-item .gallery-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-container .gallery-item .gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  transition: .3s ease-in-out;
}

.gallery-container .gallery-item:hover .gallery-image img {
  transform: scale(1.5);
}

.gallery-w-1 {
  grid-column: span 1;
}

.gallery-w-2 {
  grid-column: span 2;
}

.gallery-w-3 {
  grid-column: span 3;
}

.gallery-h-1 {
  grid-row: span 1;
}

.gallery-h-2 {
  grid-row: span 2;
}

.gallery-h-3 {
  grid-row: span 3;
}

@media (max-width: 700px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-w-3 {
    grid-column: span 2;
  }
}

@media (max-width: 1329px) {

  .upload-logo-type {
    border: 0;
  }

}

@media (max-width: 1429px) {
  .header-preview {
    padding-inline: 20px;
  }

  .upload-bg-preview-container {
    padding-inline: 32px !important;
  }

  .upload-bg-image {
    left: 38px;
  }
}

@media (max-width: 992px) {
  .upload-bg-image {
    left: 27%;
  }
}


/* Profile section */

.profile-tag {
  font-weight: 400;
  font-size: 16px;
  margin-block-end: 10px;
  color: #000;
}

.pro-img {
  position: relative;
  margin-top: 42px;
  padding: 0;
}

.pro-img img {
  width: 136px;
  height: 136px;
  padding: 10px;
  border: 2px dashed var(--theme-secondary-color) !important;
}

.pro-img .editingImage {
  opacity: 0.5;
  cursor: pointer;
}

.pro-img img.edit {
  width: 55px;
  height: 55px;
  border: 0 !important;
  position: absolute;
  top: 56%;
  right: 29%;
  cursor: pointer;
}

.pro-img img.delete {
  width: 90px;
  height: 90px;
  border: 0 !important;
  position: absolute;
  top: 56%;
  right: 19%;
  cursor: pointer;
}

.profile-headers {
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-block-end: 10px;
  font-weight: bolder;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.profile-edit-btn {
  margin-inline-end: 5px !important;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
}

.student-profile-main .tab-content {
  margin: 0;
}

.student-profile-main .tab-content {
  padding: 0 !important;
  width: 100%;
}

.student-profile-main :is(.p-info,
  .additional-info) p:first-child {
  width: 130px;
}

.student-profile-main :is(.p-info,
  .additional-info) div {
  display: flex;
}

.student-fee-table {
  height: 400px;
}

.btn-edit,
.btn-edit:hover,
.btn.btn-success {
  background: #2b4378;
  border-radius: 10px;
  white-space: nowrap;
  height: 36px;
  color: #ffffff;
  border: 0;
  font-size: 16px;
  line-height: 24px;
}

.category-sec .dropdown.c-dwn .dropdown-toggle {
  min-width: 135px;
  height: 43px;
  background: #ffffff url(../public/images/dwn-arw.svg) no-repeat 95% center !important;
  border-radius: 10px;
  font-size: 20px;
  line-height: 30px;
  color: #555555;
  border: 1px solid #555555;
}

.category-sec .popover {
  width: 890px;
  max-width: 890px;
  padding: 20px;
}

.category-sec .popover input[type="search"] {
  background: #e8e8e8 url(../public/images/search.svg) no-repeat 95% center;
  width: 367px;
  height: 41px;
  border-radius: 10px;
  font-size: 20px;
  line-height: 30px;
  color: #555555;
  border: 0;
  padding: 10px;
}

.category-sec .checkbox {
  display: inline-block;
  width: auto;
  padding: 0;
  line-height: 0px;
}

.category-sec .checkbox input[type="checkbox"] {
  margin: 0;
  visibility: hidden;
  left: 1px;
  top: 1px;
}

.category-sec .checkbox label {
  background: #c4c4c4;
  width: 27px;
  height: 27px;
  cursor: pointer;
  display: block;
  position: relative;
  transition: 0.4s ease all;
  border-radius: 10px;
  box-shadow: none;
}

.category-sec .checkbox label:before {
  content: "\f00c";
  color: #fff;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50% !important;
  transition: all 0.3s;
  width: 27px;
  height: 27px;
  border-radius: 10px;
  background: #2b4378 !important;
}

.category-sec .checkbox label:after {
  content: "";
  background: #2b4378;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50% !important;
}

.category-sec .checkbox input[type="checkbox"]:checked+label {
  background: #2b4378;
}

.category-sec .checkbox input[type="checkbox"]:checked+label:before {
  opacity: 1;
}

.category-sec .checkbox input[type="checkbox"]:checked+label:after {
  animation: shadow 0.5s;
}

.category-sec .checkbox input[type="checkbox"] {
  visibility: hidden;
}

@keyframes shadow {
  0% {
    height: 20px;
    width: 20px;
    opacity: 0.5;
  }

  100% {
    height: 40px;
    width: 40px;
    opacity: 0;
  }
}

@media only screen and (max-width: 767px) {
  .category-sec .checkbox {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 1700px) {
  .innertab-sec.paymenttab ul li.nav-item::after {
    right: -10px;
    width: 105px;
    height: 14px;
  }
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
  z-index: 99999 !important;
}

.container-tabs .form-control:focus {
  border: 1px solid #000000 !important;
}

.freeplan-modal .form-select,
.freeplan-modal .form-control,
.freeplan-modal .searchBox {
  border: 0.5px solid #000000;
  border-radius: 20px;
  height: 41px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  color: #000;
  min-width: 170px;
}

.searchBox,
.freeplan-modal .form-select {
  background: url(../public/images/sbarw.png) no-repeat 96% center !important;
  appearance: none;
  font-size: 14px;
  color: #000;
  min-width: 170px;
}

.searchBox:focus,
.freeplan-modal .form-select:focus {
  background: #ebebeb url(../public/images/sbarw.png) no-repeat 96% center !important;
  appearance: none;
  border: 0px !important;
}

.freeplan-modal .form-label {
  font-size: 16px;
  color: #000;
}

.freeplan-modal .form-check label {
  font-size: 16px;
  color: #565656;
}

.optionListContainer {
  margin-top: -5px;
}

ul.optionContainer {
  background: #e5e5e5;
  border-radius: 20px;
  border: 0px;
}

.modal-table th {
  color: #000000;
  border: 1px solid #000;
  font-weight: normal;
  font-size: 16px;
}

.modal-table td {
  color: #000000;
  border: 1px solid #000;
}

.btn-modal {
  font-size: 16px;
  color: #fff;
  background: #2b4378;
  border-radius: 10px;
  min-width: 105px;
  height: 40px;
  line-height: 0;
  border: 0;
}

.freeplan-modal .btn-cancel,
button.btn.btn-cancel.btn.btn-primary,
.btn-cancel {
  background: #313131;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  min-width: 105px;
  height: 40px;
  line-height: 0;
  border: 0;
}

.btn-modal:hover {
  background: #005994;
  color: #fff;
}

.modal-body .btn-modal:hover {
  background: #005994;
  color: #fff;
}

.modal-body .btn-primary {
  font-size: 16px;
  color: #fff;
  background: #2b4378;
  border-radius: 10px;
  min-width: 105px;
  height: 40px;
  line-height: 0;
}

.searchWrapper {
  border: 0px !important;
}

.card .table-odd tr:nth-child(odd) {
  background-color: #f3f6ff;
}

.outline-cbtn {
  /* min-height: 40px; */
  color: #005994 !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  border-color: #005994;
  height: 31px !important;
  line-height: 0.1px;
}

.outline-select {
  border: 1px solid #565656;
  color: #2b4378;
  font-size: 16px !important;
  border-radius: 10px !important;
  width: auto;
  height: 40px;
  border: 1px solid #2b4378;
}

.tab-content .outline-cbtn,
.tab-content .outline-cbtn:hover {
  color: #000 !important;
  background: #c7d1ef !important;
  /* border-radius: 20px !important; */
  height: 39px;
  font-size: 16px !important;
  line-height: 24px;
  border: 0;
}

.tab-content .outline-select {
  border: 0;
  color: #000 !important;
  font-size: 16px !important;
  background: #c7d1ef !important;
  border-radius: 20px !important;
  width: auto;
  height: 39px !important;
  appearance: auto !important;
}

.btn-outline-primary:hover {
  border: 0;
}

.btn-primary:hover {
  border: 0;
}

button.border-0.bg-transparent.p-0.btn.btn-primary:hover {
  background: transparent !important;
}

.btn-udate {
  border-radius: 8px !important;
  color: #005994;
  font-family: "robotomedium";
  font-size: 24px;
  color: #fff !important;
}

.btn-outline-primary.outline-cbtn:hover {
  background: #ecf0ff;
  border: 1px solid #2b4378;
  border-radius: 10px;
  color: #000000;
}

.btn-outline-primary.outline-cbtn:active,
.btn-outline-primary.outline-cbtn:focus {
  background: #2b4378;
  border: 1px solid #2b4378;
  border-radius: 10px;
  color: #fff !important;
}

.feestructure {
  width: 100%;
  padding: 20px 0;
  margin: 10px 0;
}

.feeul {
  list-style: none;
  display: flex;
  padding: 20px 0;
  flex-flow: wrap;
  margin: 20px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.feeul li {
  display: flex;
  align-items: center;
  width: 33.3%;
  text-align: left;
  margin-bottom: 10px;
}

.feeul li .flabel {
  width: 40%;
}

.feeul li label {
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

.feeul li input,
.feeul li select,
.feeul li .form-select {
  height: 30px !important;
  background: #e3e3e3 !important;
  border-radius: 10px !important;
  border: 0 !important;
  padding: 2px 6px !important;
  font-size: 14px !important;
  appearance: auto;
}

.feeul li input[type="checkbox"] {
  height: 0;
}

.feeul li input.date-input {
  width: 110px;
  font-size: 12px;
  min-width: inherit;
}

.feeul li .checkbox label {
  margin-top: -25px;
}


/* media queries*/

@media only screen and (max-width: 1700px) {
  .feeul li label {
    font-size: 14px;
  }

  .f-28 {
    font-size: 20px;
  }

  .cardbg {
    width: 120px;
  }

  .cardgroup-custom .card-text {
    font-size: 12px;
  }

  .cardgroup-custom .card-body {
    padding: 0px 12px 0px 10px;
  }

  .content-tabs label.form-label.clabel {
    min-height: 41px;
    font-size: 14px !important;
  }

  .content-tabs .nav-tabs .nav-link.active {
    font-size: 16px;
    padding: 0.39rem 1rem;
  }

  .content-tabs .nav-tabs .nav-link {
    font-size: 1rem;
  }

  .section-bgwhite1 {
    padding: 25px 40px;
  }

  .content-tabs .tab-content {
    padding: 20px 0px;
  }

  .outline-cbtn,
  .outline-select,
  .btn-save {
    font-size: 14px !important;
  }

  .content-tabs label.form-label {
    font-size: 14px;
  }

  .f-24 {
    font-size: 18px;
  }

  .f-29 {
    font-size: 29px;
  }

  .c-table tbody td,
  .c-table thead th {
    font-size: 14px;
  }

  .btn-reset {
    font-size: 20px;
    height: 40px;
    margin-top: 30px;
  }

  label.custom-checklabel {
    font-size: 14px;
  }

  .custom-control,
  .custom-control.form-select {
    font-size: 16px;
  }

  .check-label .form-check label,
  .list-group li a {
    font-size: 14px;
  }

  .list-group li a .fw-title {
    font-size: 16px;
  }

  .outline-assign {
    font-size: 14px;
    height: 30px;
  }

  .container-tabs .tab-content {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1400px) {
  .innertab-sec.paymenttab ul li.nav-item::after {
    right: -18px;
  }

  .inner-section .d-flex.justify-content-between.mb-4 {
    flex-flow: wrap;
  }

  .f-28 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 20%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
  }

  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }

  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    /* padding: 0.6em 1.6rem; */
    width: 75%;
  }

  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #ffffff;
  }

  .cd-timeline-content .cd-read-more {
    float: left;
  }

  .cd-date {
    position: absolute;
    width: 100%;
    left: 3%;
    top: 22%;
  }

  .cd-timeline-block .cd-timeline-content {
    float: right;
  }

  /* .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #ffffff;
  } */
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }

  .cd-timeline-block.cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }

  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }

  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {

  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@media only screen and (max-width: 1700px) {
  .innertab-sec.paymenttab ul li.nav-item::after {
    /* right: -10px; */
    width: 78px;
    height: 14px;
  }

  .div-min-wdth {
    min-width: 93px;
  }
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .category-sec .checkbox {
    margin: 0 0 20px;
  }
}

.testClassName {
  background: #cbd6ff;
  border-radius: 10px;
  color: #000000 !important;
}

.fc-agenda-slots td div {
  height: 100px;
}

.list-card {
  height: auto;
  border-bottom: 0.5px solid #555555;
}


/* .event-list {
  border: 0.5px solid #555555;
  border-top: transparent;
  border-left: transparent;
  border-bottom: transparent;
} */

.event-tabs {
  border: 0.5px solid #555555;
  border-top: transparent;
  border-left: transparent;
  border-bottom: transparent;
}

.event-tabs .tab-content {
  padding: 0;
}

.event-tabs .updated-title {
  font-size: 14px;
}


/* Dairy css */

.dairy-header {
  display: flex;
  align-items: center !important;
  border-bottom: 1px solid #DDD;
}

.dairy-header p {
  padding-block: 10px;
}

.dairy-header p:nth-of-type(1) {
  width: 33.6% !important;
  border-right: 1px solid #DDD;
}

.dairy-header :last-child {
  width: 100%;
  padding-left: 25px;
}

.dairy-side-heading {
  border-right: 1px solid #DDD;
  width: 25.03%;
  padding-top: 35px !important;
}

:is(.theme-content-container) .theme-content-table.dairy-table tbody tr {
  height: 182px !important;
  line-height: 178px !important;
}

:is(.theme-content-container) .theme-content-table.dairy-table tbody td {
  height: 182px !important;
}

.dairy-content .quill {
  min-height: 78px !important;
}

.dairy-content .quill .ql-container {
  min-height: 78px !important;
}

.tab-content :is(select.basic-input-controls,
  input.basic-input-controls,
  textarea.basic-input-controls) {
  border-radius: 10px !important;
  border-color: black;
  height: auto;
}

.dairy_td {
  width: "30%";
}

.spinner {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 9;
  background-color: #fffffff2;
}

.spinner>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.w-70 {
  width: 70% !important;
  max-width: 70% !important;
}

.w-20 {
  width: 20%;
}

.bg-time {
  /* background-color: #ecf0ff; */
  min-width: 250px;
}

.bg-timeCard {
  background: #cbd6ff;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 10px #94a7df;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  color: #005994;
}

.add-btn span {
  width: 23px;
  border-radius: 100%;
  background: #cbd6fe;
  padding: 0px 5px;
}

.small-text {
  color: #666666;
  font-size: 10px;
}

.event-modal {
  max-width: 500px !important;
}

.f-12 {
  font-size: 12px;
}

.overflow-x {
  overflow: hidden;
  overflow-x: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}


/* Track */

.overflow-x::-webkit-scrollbar-track {
  background: red;
}


/* Handle */

.overflow-x::-webkit-scrollbar-thumb {
  background: black;
}


/* Handle on hover */

.overflow-x::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  box-shadow: none;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}


/* .pagination .previous {
  background: #2b4378;
  border-radius: 25px 25px 10px 25px;
  width: 44.21px;
  align-items: center;
  height: 44.21px;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}

.pagination .next {
  background: #2b4378;
  border-radius: 25px 25px 10px 25px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 44.21px;
  align-items: center;
  height: 44.21px;
  justify-content: center;
  display: flex;
  margin-left: 10px;
} */

.pagination li a {
  padding: 5px 10px !important;
}


/* .pagination li.active a {
  border: 1px solid #2b4378;
  background-color: transparent !important;
  border-color: #2b4378 !important;
  color: #2b4378 !important;
  border-radius: 100% !important;
} */

.slot-header,
.session-header {
  border-bottom: 0.5px solid #999999 !important;
  border-right: 0.5px solid #999999 !important;
  border-left: 0.5px solid #999999 !important;
}

.position-buttons {
  position: absolute;
  right: 0;
  top: -53px;
}

.infinite-scroll-component {
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}

#cd-timeline {
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}

.dwnnavbar .nav-link {
  border: none !important;
}

.table .btn-danger {
  color: #dc3545;
  /* background-color: #dc3545; */
  background-color: white;
  border-color: #dc3545;
  border-radius: 8px;
  border-radius: 8px;
}

.f-nowrap {
  flex-wrap: nowrap !important;
  justify-content: space-between;
}

.fc-content-skeleton tr {
  border-bottom: none !important;
}

.f-nowrap .nav-item,
.f-nowrap .nav-link {
  width: 100%;
}

.fc-title {
  color: #fff !important;
}

.item-index {
  border-radius: 100%;
  border: 1px solid;
  height: 20px;
  display: flex;
  width: 20px;
  padding: 5px;
  align-items: center;
}

.error-card {
  animation: shake 1s;
  border: 1.5px solid red;
  /* animation-iteration-count: infinite; */
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  /* 60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); } */
  /* 80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); } */
}

input[type="date"],
input[type="time"] {
  border-radius: 10px;
  /* height: 42px; */
  border: 1px solid;
}

.container-tabs .form-select {
  border: 1px solid #2b4378;
  border-radius: 10px;
  color: #2b4378;
}


/* #react-select-5-input {
  border: none !important
} */

.css-1s2u09g-control {
  outline: none;
  border-radius: 10px !important;
  border: 0.5px solid #000000 !important;
}

.position-buttons-tab {
  position: absolute;
  right: 0px;
  top: -11px;
}

.feeul:not(:last-child) {
  border-bottom: none !important;
}

.edu-card {
  background: #f0f0f0;
}

.box-check {
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.dynamic-variables {
  border: 1px solid #73809D !important;
  border-radius: 6px !important;
  height: 104px;
  padding: 8px;
}

.dynamic-label {
  position: relative;
  margin: 0px 3px 0px 3px;
  padding: 2px 4px;
  cursor: pointer;
  background: #dadaf2;
  border-radius: 5px;
  font-size: var(--theme-small-fs) !important;
}

.clipPopover {
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.popover-content {
  white-space: nowrap;
}

.preview {
  height: 155px;
  padding: 8px;
  color: var(--theme-dark-color) !important;
  font-size: var(--theme-small-fs) !important;
  border: 0.5px solid var(--theme-secondary-color) !important;
  border-radius: 6px !important;
  overflow-x: auto;
}

.preview .preview-pre {
  width: 100%;
  overflow-x: auto !important;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.create-Dlttemplate .modal-body form {
  max-width: 100% !important;
}

.approval-modal .modal-dialog {
  max-width: 500px !important;
}

.f-10 {
  font-size: 10px;
}

.css-1s2u09g-control input {
  border: none !important;
}

.btn-outline-primary {
  border-color: #2b4378;
  color: #2b4378 !important;
}

.edu-card {
  background: #f0f0f0;
}

.form-input {
  width: 100px !important;
}

.text-primary-col {
  color: #2b4378 !important;
}

.fav-card {
  border: 1px solid #73809D;
  border-radius: 6px;
}

.fav-card:hover {
  background-color: #ecf0ff;
}

.fav-card .text-primary-col {
  text-decoration: underline;
  cursor: pointer;
}

.reset-modal .modal-dialog {
  width: 400px;
}


/* popover */

.popoverCustom {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px !important;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.5rem !important;
}


/* .dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
} */

.hint {
  /* height: 22px;
  width: 23px;
  color: white;
  background-color: rgba(0,0,0,0.85);
  border-radius: 15px;
  right: -15px;
  top: -12px;
  transition: .2s ease;
  display: flex;
  justify-content: center;
  align-items: center; */
  height: 18px;
  width: 15px;
  color: white;
  background-color: #000000c7;
  border-radius: 15px;
  right: -13px;
  top: -20px;
  transition: 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 22px; */
  /* margin-bottom: -6px; */
}

.fa {
  display: inline-block;
  color: white;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: small !important;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.big-form form {
  max-width: 100% !important;
  width: 100% !important;
}

.breadcrumb {
  font-size: 12px;
  margin-bottom: 0;
}

.breadcrumb-item {
  padding-left: 0.1rem;
}

.breadcrumb-item a {
  color: var(--theme-dark-color);
  padding-left: 0;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 5px;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "/" !important;
  color: var(--theme-dark-color);
  padding-right: 5px;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: var(--theme-primary-color);
}

#RFS-StepButton {
  width: 30px;
  height: 30px;
  background-color: #ecf0ff;
}

#RFS-StepButton span {
  color: #a7a9d9;
}

#RFS-Connector {
  border-top: 1px dashed #2b4378;
}

#RFS-Label {
  color: #606060;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

#RFS-ConnectorContainer {
  top: calc((30px - 1px) / 2);
}

#RFS-StepButton.completed {
  background-color: #2b4378;
  background-image: url("/public/images/Group 1169.png");
  background-repeat: no-repeat;
  background-position: center;
}

#RFS-StepButton.completed span {
  display: none;
}


/* Bulk enroll */

.bulk-enroll-file {
  border: 2px dashed #8A9DE7 !important;
  background-color: #F5F5F8 !important;
  height: 57.7px !important;
  border-radius: 6px !important;
}

.bulk-enroll-file label {
  cursor: pointer;
  height: 57.7px !important;
}

.bulk-enroll-sample {
  border-bottom: 1px solid #DDD;
  padding-block: 8px;
  padding-inline: 10px;
  display: flex;
  justify-content: space-between;
}

form input[aria-autocomplete="list"] {
  border: none !important;
}


/* enquiry followup */

.btn-light-edit {
  background-color: white;
  border-radius: 10px !important;
  border: 1px solid #2b4378;
  color: #2b4378;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 10px !important;
}

.add-enquiry-tabs .nav-item .nav-link {
  background-color: #ECF0FF;
}

.underline {
  border-bottom: 0.5px solid #777777;
}

.enquiry-modal .modal-dialog {
  min-width: 495px;
  width: 30% !important;
}

@media print {
  table {
    border: solid #000 !important;
    border-width: 1px 0 0 1px !important;
  }

  th,
  td {
    border: solid #000 !important;
    border-width: 0 1px 1px 0 !important;
  }
}

.Active-student-List {
  background-color: #ECF0FF;
}

.other-classname.col-md-5 {
  flex: 0 0 auto;
  width: 19.666667%;
}

.theme-content-container .theme-content-filters.theme-content-filter-justify-start {
  justify-content: flex-start !important;
}

.theme-content-container .theme-content-filters.theme-content-filter-no-border {
  border-block-end: none !important;
}

.theme-content-cards {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  grid-auto-rows: auto !important;
  grid-gap: 1rem !important;
  padding: 1rem !important;
  border-block-end: 1px solid #DDDDDD !important;
}

.theme-card {
  border-radius: 6px !important;
  padding: 0 1rem !important;
  position: relative;
  transition: border 0.2s ease;
  border: 0.5px solid transparent;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.theme-card:hover {
  /* border: 0.5px solid #C9CDFF !important; */
}


.theme-content-cards .theme-card .theme-card-header p {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.theme-content-cards .theme-card .theme-card-footer {
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-top: -1rem !important;
  color: #73809D !important;
}

.theme-content-container .theme-content-filters.theme-content-filter-justify-start {
  justify-content: flex-start !important;
}

.theme-content-container .theme-content-filters.theme-content-filter-no-border {
  border-block-end: none !important;
}

.active-student-list {
  background-color: #ECF0FF;
}

.favourite-star-icon {
  width: 21px;
  cursor: pointer;
}

.certificate-issue-gender-color {
  color: #CD1CDC;
}

.certificate-modal-md .modal-dialog {
  max-width: 874px;
  margin-inline: auto !important;
}

.certificate-modal-md .modal-content {
  min-height: 175px !important;
  border: 0 !important;
}

.school-gallary-upload-img {
  display: inline-flex;
  margin-top: 118px;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 142px;
  background: #D9D9D9;
  border-radius: 50%;
}


.school-gallary-upload-icon {
  width: 40px;
  height: 40px;
}

.upload-gallary-text {
  position: absolute;
  width: 362px;
  height: 12px;
  left: 256px;
  margin-top: 47px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #000000;
}

.assignsubject-modal-md.modal-dialog {
  position: absolute;
  width: 540px;
  height: 510px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  border-radius: 10px;
}

.school-gallary-images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px;
}

.school-gallary-remove {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: 189px;
  margin-top: -7px;
  /* background-color: #FFB8B8; */
}

.upload-gallary-input {
  display: none;
}

.gallary-header {
  width: 783px;
  height: 58px;
  margin-left: 350px;
  margin-top: -9px;
  font-family: 'Lato';
  /* font-style: normal; */
  font-weight: 400;
  /* font-size: 24px; */
  line-height: 34px;
  text-align: center;
  color: #000;
  border-radius: nullpx;
}

.assign-role-header {
  position: absolute;
  width: 94px;
  height: 22px;
  left: 116px;
  margin-top: -7px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
}

.assign_role-btn {
  margin-right: 78%;
  cursor: pointer;

}

.gallery-header {
  display: inline-block;
  width: min(100%, 813px);
}

.upload-gallary-img {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.gallery-upload {
  width: 61px;
  height: 58px;
  margin-left: -132px;
  margin-top: -20px
}

.gallary-upload-text {
  position: absolute;
  width: 178px;
  height: 24px;
  margin-left: 13px;
  margin-top: -44px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #2B4378;
}

.modal-delete-icon-border {
  background-color: #DDD !important;
  ;
  width: 100%;
  height: 1px;
}

.myprofile-loader {
  margin-left: -48%;
  margin-bottom: -2%;
}


.applicationsettings-getdata {
  margin-right: 30%;
}

:is(.modal, .theme-content-container) .theme-content-table tbody td.row-spanned-col {
  line-height: 137px !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody td.exam-rule-setup-multiselect {
  line-height: 15px !important;
}

.go-back {

  background-color: transparent !important;
  border: 1px solid var(--theme-primary-color) !important;
  font-weight: 500 !important;
  color: var(--theme-primary-color) !important;
}

.go-back:hover {
  background-color: #edeff5 !important;
}

.ruleSetUp-add-evaluation {
  border: none;
  outline: none;
  background: #FCEDB9;
  border-radius: 6px;
  /* padding: 6px;
  height:50%;
  margin-top: 10%; */
}

.theme-content-container .rule-set-up-progress-tabs {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 16px;
  min-height: 67px !important;
  padding-block: 8px !important;
  padding-inline-start: 8px !important;
  padding-inline-end: 16px !important;
  margin-block: 0px !important;
  border-block-end: 1px solid #DDDDDD !important;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rule-set-up-back-btn {
  position: relative !important;
  margin-left: 2% !important;
  /* margin-t: 1%; */
  background: #FFFFFF !important;
  color: #2B4378 !important;

}

.rule-set-up-terms-button {
  position: relative;
}

.rule-set-up-final.active {
  background-color: var(--theme-primary-color);
  color: #fff;
}

.rule-set-up-terms-button.active {
  background-color: var(--theme-primary-color);
  color: #fff;
}

.rule-set-up-terms-button::before {
  content: "";
  position: absolute;
  top: 52%;
  left: 100%;
  width: 100%;
  height: 1px;
  background-color: #000;
  transform: translateY(-100%);
}

.exam-rule-setup-last-button::before {
  right: 0;
  width: 50px;
}

.horizontal-line {
  width: 20px;
  flex-grow: 1;
  height: 1px;
  background-color: #000;
  margin: 0 10px;
}


.feeplan-form-col-width {
  min-width: 12rem !important;
}

.feeplan-div-col-width {
  min-width: 13rem !important;
}

.staffenroll-heading {
  width: 267px;
  height: 36px;
  left: 16px;
  top: 51px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #73809D;
}

.password-input-paddingleft {
  padding-right: 2.5rem !important;
}

.shift-timings {
  margin-left: 86px;
  margin-top: 17px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #73809D;

}

.table-container {
  overflow-x: auto;
}

.notifi-viewAll {
  color: #005994;
  margin-left: 90%;
}

.notifi-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme-content-container-expense {
  width: 94% !important;
  margin-block-end: 14px !important;
  padding: 10 !important;
  margin-left: 42px !important;
  background-color: #fff !important;
}

.theme-content-container-expenses {
  width: 46% !important;
  margin-block-end: 14px !important;
  padding: 10 !important;
  margin-left: 45px !important;
  background-color: #fff !important;
}

.theme-content-container-expendiature {
  width: 46% !important;
  margin-block-end: 14px !important;
  padding: 10 !important;
  /* margin-left: 45px !important; */
  background-color: #fff !important;
}

.theme-content-cards-expense {
  display: grid !important;
  /* grid-template-columns: repeat(6, 1fr) !important; */
  grid-template-columns: repeat(auto-fit, minmax(176.87px, 1fr));

  grid-gap: 1rem !important;
  padding: 1rem !important;
}

.theme-content-cards .theme-card .theme-card-footer-expense {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
  margin-top: -1rem;
}


:is(.modal, .theme-content-container) .theme-content-table.table-hover>tbody>tr.no-hover-row.fee-total-row>* {
  background-color: #ECF0FF !important;
}

.table th.cursor-pointer {
  cursor: pointer !important;
}

.table th.cursor-pointer:hover {
  background-color: var(--theme-hover-color);

}

.table th.sort-head:hover {
  background-image: url("/public/images/sort-by.svg") !important;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 60%;
}

.inventory-kit-add-product-btn {
  height: 22px !important;
  min-width: 96px !important;
  padding-inline: 8px !important;
  font-size: var(--theme-context-fs) !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 6px !important;
}

.student-profile-container {
  flex: initial;
  margin-inline: 0 !important;
  margin-left: 14px !important;
}

.student-profile-back-btn {
  position: relative !important;
  margin-left: 2% !important;
  background: #FFFFFF !important;
  color: #2B4378 !important;
}

;

.m-auto {
  margin: auto !important;
}

.mt-20 {
  margin-top: 20px !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody tr.profile-Salary-breakdown {
  background-color: #F4F6FF !important;
  border-bottom: 0px;
}

/* SOCIAL MEDIA POST CARD STARTS */
.social_post_container {
  margin: 17px 10px;
  padding: 2px;

}

.social_media_publish_container {
  margin: 2px 2px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
}

/* SOCIAL MEDIA POST CARD ENDS */

/* ACTIVITY CARD STARTS */

.activity-upload-container {
  width: 120px;
  height: 30px;
  display: flex;
  cursor: pointer;

}

.activity-upload {
  border: 1px dotted #2B4378;
  border-radius: 25px;
  cursor: pointer;

}

.activity-upload-text {
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #2B5678;
  margin-top: 5px;

}

.activity-image-container {
  width: 55px;
  height: 52px;
  border-radius: 10px;
  background-color: #F5F5F8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: -10px;
  cursor: pointer;
}

.activity-image-delete-button {
  position: absolute;
  top: -8px;
  right: -8px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

/* ACTIVITY CARD ENDS */

/* STUDENT PROFILE STARTS */

.studentProfileAttendanceStatusBtn{
  width: 100px;
  height: 30px;
  padding: 1px 2px ;
  gap: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF
}

/*  STUDENT PROFILE ENDS */


/* STAFF DOCUMENT UPLOAD  STARTS HERE */

.docs-main-background{
    background-color: #F8F8F8;
    border-radius: 8px
}

.upload-doc-text{
    color: #8291B0;
}
.upload-doc-text > .browse {
    color: #1C5CE9
}


.upload-doc-img-container {
    /* padding: 60px 30px !important; */
    height: 213px;
    max-width: 352px;
    min-width: 200px;
    border: 2px dashed #E6E6E6 !important;
    border-radius: 12px;
    background: #F6FBFE;
    /* position: relative; */
}

.image-preview-container{
    /* padding: 10px 30px !important; */
    height: 213px;
    max-width: 300px;
    min-width: 250px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* border: 2px dashed #E6E6E6 !important; */
    border-radius: 12px;
    position: relative;
    /* background: #F6FBFE; */
}

.image-preview{
    width: 200 !important;
    height: 189px !important;
    border-radius: 12px;
}

.doc-image-delete-button {
    position: absolute;
    top: 2%;
    right: -2%;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

.doc-document-delete-button {
    position: absolute;
    top: 2%;
    right: -2%;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.upload-doc-modal-md .modal-header {
    background-color: #1C1C1C0D;
    border-bottom: none !important;
}

.upload-doc-modal-md .modal-dialog {
    max-width: 752px;
    margin-inline: auto !important;
  }

  
.upload-doc-modal-md .modal-content {
    min-height: 378px !important;
    max-height: 440px !important;
    border: 0 !important;
    border-radius: 10px !important;
}


/* custom doc upload styles below */

.custom-upload-doc-modal-lg .modal-header {
    background-color: #1C1C1C0D;
    border-bottom: none !important;
}

.custom-upload-doc-modal-lg .modal-dialog {
    max-width: 1209px;
    margin-inline: auto !important;
  }

  
.custom-upload-doc-modal-lg .modal-content {
    min-height: 700px !important;
    max-height: 789px !important;
    border: 0 !important;
    border-radius: 10px !important;
}

.custom-upload-doc-img-container {
    height: 178px !important; 
    border: 2px dashed #E6E6E6 !important;
    border-radius: 12px;
    background: #F6FBFE;
}
  
.custom-doc-image-delete-button {
    position: absolute;
    /* top: 3%; */
    right: 5%;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.custom-doc-image-logo-icon {
    position: absolute;
    /* top: 3px; */
    left: 1%;
    border: none;
    border-radius: 50%;
    /* cursor: pointer; */
}

.custom-image-preview-container{
    /* padding: 10px 30px !important; */
    min-height: 600px;
    max-height: 650px;
    max-width: 576px;
    min-width: 400px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* border: 2px dashed #E6E6E6 !important; */
    border-radius: 12px;
    /* background: #F6FBFE; */
}

.custom-image-preview{
    width: 544px !important;
    height: 581px !important;
    border-radius: 12px;
    border: 2px dashed #6B6B6B !important;
    /* margin-bottom: 20px; */
}

.custom-image-container{
    min-height: 600px;
    max-height: 670px;
    max-width: 576px;
    min-width: 400px;
    /* border: 2px dashed #E6E6E6 !important; */
    border-radius: 12px;
    background: #F8F8F8;
}

.custom-all-image-info{
    /* overflow-y: scroll; */
    min-height: 200px;
    max-height: 300px;
    position: relative;
    scrollbar-color: #2b4378 transparent;
}


/* preview modal styles below */

.preview-upload-doc-modal-lg .modal-header {
    background-color: #1C1C1C0D;
    border-bottom: none !important;
}

.preview-upload-doc-modal-lg .modal-dialog {
    max-width: 627px;
    margin-inline: auto !important;
  }

  
.preview-upload-doc-modal-lg .modal-content {
    min-height: 700px !important;
    max-height: 766px !important;
    border: 0 !important;
    border-radius: 10px !important;
}

.preview-image-container{
    max-height: 781px !important;
    max-width: 550px !important;
    min-height: 400px !important;
    min-width: 500px !important;
    /* border: 2px dashed #E6E6E6 !important; */
    border-radius: 12px;
    box-shadow: "2px 4px 8px 0.3px rgb(158,158,158, .5)";
    background: #ffffff;
}

.preview-image-preview-container{
    /* padding: 10px 30px !important; */
    max-height: 581px;
    max-width: 576px;
    min-height: 500px;
    min-width: 483px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* border: 2px dashed #E6E6E6 !important; */
    border-radius: 12px;
    /* background: #F6FBFE; */
}

.preview-image-preview{
    width: 483px !important;
    height: 581px !important;
    border-radius: 12px;
    border: 0.6px solid #5a5a5a;
    box-shadow: 2px 4px 8px 0.3px rgb(158,158,158, .5);
}

/* STAFF DOCUMENT UPLOAD ENDS HERE */


/* SORT SETTINGS STYLES START HERE */

.sortBorder{
  border: 1px solid #DDDDDD !important;
  padding: 1%;
  align-items: center;
  margin-bottom: 1%;
}

/* SORT SETTINGS STYLES ENDS HERE */


